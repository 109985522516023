import { Badge, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import RelativeBadge from '../RelativeBadge';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useNavigation } from '@/providers/navigation-provider';
import { Notifications } from '../notifications/Notifications';
import { useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { NotificationsDocument } from '@/graphql/generated/notifications.generated';
import { CountUnreadNotificationsDocument } from '@/graphql/generated/count-unread-notifications.generated';
import { useTranslation } from 'react-i18next';
import { NotificationTab } from '@/graphql/generated/types.generated';

export default function MainNavNotifications() {
    const { t } = useTranslation();
    const { mainNavOpen } = useNavigation();
    const [open, setOpen] = useState(false);
    const [, queryResult] = useLazyQuery(NotificationsDocument, {
        notifyOnNetworkStatusChange: true,
    });
    const { data } = useQuery(CountUnreadNotificationsDocument, { notifyOnNetworkStatusChange: true });

    const countUnreadNotifications = data?.countUnreadNotifications.find(
        (tab) => tab.tab === NotificationTab.All,
    )?.value;

    return (
        <>
            <Tooltip
                title={t('str.notifications')}
                arrow
                placement="right"
                disableHoverListener={mainNavOpen}
            >
                <ListItemButton
                    component={'div'} // Needed for the tooltip to work
                    dense={true}
                    sx={{ borderRadius: '99px' }}
                    {...(!mainNavOpen && { sx: { justifyContent: 'center' } })}
                    onClick={() => {
                        setOpen(true);
                        queryResult.refetch();
                    }}
                >
                    <ListItemIcon {...(!mainNavOpen && { sx: { minWidth: 0 } })}>
                        <Badge badgeContent={countUnreadNotifications} color="error" invisible={mainNavOpen}>
                            <NotificationsRoundedIcon />
                        </Badge>
                    </ListItemIcon>
                    {mainNavOpen && (
                        <Stack direction="row" alignItems="center" flex={1}>
                            <ListItemText>
                                <Typography noWrap variant="body2">
                                    {t('str.notifications')}
                                </Typography>
                            </ListItemText>
                            <RelativeBadge badgeContent={countUnreadNotifications} color="error" />
                        </Stack>
                    )}
                </ListItemButton>
            </Tooltip>
            <Notifications open={open} onClose={() => setOpen(false)} query={queryResult} />
        </>
    );
}
