import { Box, Icon, Tooltip, Typography } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import { useNavigation } from '@/providers/navigation-provider';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

export default function MainNavTrialCta({ subscriptionExpireDate }: { subscriptionExpireDate: string }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { mainNavOpen } = useNavigation();

    const daysRemaining = subscriptionExpireDate ? dayjs(subscriptionExpireDate).diff(dayjs(), 'days') : 1;
    const daysRemainingNoZero = daysRemaining <= 0 ? 1 : daysRemaining;

    return mainNavOpen ? (
        <Box
            sx={{
                px: 2,
                py: 1,
            }}
        >
            <Typography color={colors.yellow[600]} noWrap>
                {t('str.cta.trial.start-sub')}
            </Typography>
            <Typography variant="body2" color={theme.palette.text.secondary} noWrap>
                {t('str.cta.trial.days-trial', {
                    count: daysRemainingNoZero,
                    days: daysRemainingNoZero,
                })}
            </Typography>
        </Box>
    ) : (
        <Tooltip
            title={t('str.cta.trial.days-trial', {
                count: daysRemainingNoZero,
                days: daysRemainingNoZero,
            })}
            arrow
            placement="right"
        >
            <Icon sx={{ display: 'flex', margin: '1.5rem auto' }}>
                <NewReleasesIcon sx={{ color: colors.yellow[600] }} />
            </Icon>
        </Tooltip>
    );
}
