import { Locales, MUI_MAP_LOCALES } from '@/libs/i18n/settings';
import theme from '@/theme';
import { createTheme, ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
    const { i18n } = useTranslation();

    const themeWithLocale = useMemo(
        () => createTheme(theme, MUI_MAP_LOCALES[i18n.language as Locales]), // Here we use "as" because the i18n.language must always be limited to the supportedLocales
        [i18n.language],
    );

    return (
        <ThemeProviderMui theme={themeWithLocale} defaultMode={'light'}>
            {children}
        </ThemeProviderMui>
    );
};
export default ThemeProvider;
