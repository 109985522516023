import { TEAM_RESTRICTED_ROUTES } from '@/constants';
import { TeamPermission } from '@/graphql/generated/types.generated';

const hasAllPermissions = (myPermissions: TeamPermission[], requirePermissions: TeamPermission[]) => {
    for (const requiredPermission of requirePermissions) {
        if (!myPermissions.includes(requiredPermission)) {
            return false;
        }
    }
    return true;
};

/**
 * Checks if user has access to given route.
 * Note: this fn uses a path dependency restriction, which means, if the access is restricted to "/settings", all sub pages will also be restricted.
 * @param pathname
 * @param permissions
 * @returns
 */
export const hasRouteTeamPermission = (pathname: string, permissions: TeamPermission[]) => {
    for (const restrictedRoute of TEAM_RESTRICTED_ROUTES) {
        if (
            restrictedRoute.route.test(pathname) &&
            !hasAllPermissions(permissions, restrictedRoute.permissions)
        ) {
            return false;
        }
    }
    return true;
};
