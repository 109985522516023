import { LocalizationProvider as LocalProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/pt';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt-br';

export default function LocalizationProvider({ children }: { children: React.ReactNode }) {
    const { i18n } = useTranslation();

    return (
        <LocalProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language || 'en'}>
            {children}
        </LocalProvider>
    );
}
