import { useSession } from '@/providers/session-provider';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';

const Context = createContext<{ socket: Socket | null } | null>(null);

export function SocketProvider({ children }: { children: React.ReactNode }) {
    const { me, refetch } = useSession();
    const [socket] = useState<Socket>(
        io(import.meta.env.VITE_SOCKETS_ROOT, {
            secure: true,
            transports: ['websocket'],
            autoConnect: false,
        }),
    );

    useEffect(() => {
        if (socket) {
            socket.on('connect_error', () => {
                refetch();
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (socket) {
            socket.auth = {
                token: me.socketToken,
            };
            socket.connect();
        }
    }, [me.socketToken, socket]);

    return <Context.Provider value={{ socket }}>{children}</Context.Provider>;
}

export function useSocket() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
