import type { InitOptions } from 'i18next';
import { ptPT, enUS, esES, ptBR, frFR } from '@mui/x-data-grid/locales';
import { Localization } from '@mui/x-data-grid/internals';

// map language to country code
export const LANGUAGES_FLAGS: Record<string, string> = {
    ['en']: 'gb',
    ['pt']: 'pt',
    ['es']: 'es',
    ['pt-br']: 'br',
    ['fr']: 'fr',
};
export const FALLBACK_LOCALE = 'en';
export const supportedLocales = ['en', 'pt', 'fr', 'pt-br', 'es'] as const;
export type Locales = (typeof supportedLocales)[number];
export const DEFAULT_NS = 'translation';
export const LANGUAGE_COOKIE = 'language';

export const MUI_MAP_LOCALES: Record<Locales, Localization> = {
    pt: ptPT,
    es: esES,
    en: enUS,
    'pt-br': ptBR,
    fr: frFR,
};

export function getOptions(ns = DEFAULT_NS): InitOptions {
    return {
        // debug: true, // Set to true to see console logs
        supportedLngs: supportedLocales,
        fallbackLng: FALLBACK_LOCALE,
        defaultNS: DEFAULT_NS,
        ns,
    };
}
