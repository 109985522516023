import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import UTC from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import Weekday from 'dayjs/plugin/weekday';

dayjs.extend(RelativeTime);
dayjs.extend(UTC);
dayjs.extend(Timezone);
dayjs.extend(Weekday);

export default dayjs;
