import {
    Badge,
    BadgeProps,
    Icon,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    PopoverActions,
    Skeleton,
    Tooltip,
    Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import WorkspaceSwitcher from '../WorkspaceSwitcher';
import { useNavigation } from '@/providers/navigation-provider';
import { MainNavQuery } from '@/graphql/generated/main-nav.generated';
import { styled } from '@mui/material/styles';
import WorkspaceAvatar from '../workspace/WorkspaceAvatar';

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        width: '0.625rem',
        height: '0.625rem',
        border: '0.125rem solid white',
        borderRadius: '0.3125rem',
    },
}));

export default function MainNavAccount({
    currentWorkspace,
}: {
    currentWorkspace?: MainNavQuery['currentWorkspace'];
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const { mainNavOpen } = useNavigation();
    const popoverActions = useRef<PopoverActions>(null);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Tooltip
                title={currentWorkspace?.name}
                arrow
                placement="right"
                disableHoverListener={mainNavOpen}
            >
                <ListItemButton
                    component={'div'} // Needed for the tooltip to work
                    sx={{ borderRadius: '99px' }}
                    {...(!mainNavOpen && { sx: { justifyContent: 'center' } })}
                    onClick={handleClick}
                >
                    <ListItemAvatar {...(!mainNavOpen && { sx: { minWidth: 0 } })}>
                        <StyledBadge
                            color="error"
                            variant="dot"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={0}
                        >
                            {currentWorkspace ? (
                                <WorkspaceAvatar
                                    {...currentWorkspace}
                                    sx={(theme) => ({
                                        width: theme.spacing(3),
                                        height: theme.spacing(3),
                                        fontSize: theme.spacing(1.5),
                                    })}
                                />
                            ) : (
                                <Skeleton
                                    variant="rounded"
                                    sx={{ width: '1.5rem', height: '1.5rem' }}
                                    animation="wave"
                                />
                            )}
                        </StyledBadge>
                    </ListItemAvatar>
                    {mainNavOpen && (
                        <>
                            <ListItemText>
                                {currentWorkspace ? (
                                    <Typography noWrap variant="body2">
                                        {currentWorkspace?.name}
                                    </Typography>
                                ) : (
                                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" />
                                )}
                            </ListItemText>
                            <Icon color="action" sx={{ display: 'flex' }}>
                                <UnfoldMoreOutlinedIcon />
                            </Icon>
                        </>
                    )}
                </ListItemButton>
            </Tooltip>
            <Popover
                action={popoverActions}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                slotProps={{ paper: { sx: { width: '100%', maxWidth: '22.5rem' } } }}
            >
                <WorkspaceSwitcher
                    currentWorkspace={currentWorkspace}
                    popOverLoadingComplete={() => popoverActions.current?.updatePosition()}
                />
            </Popover>
        </>
    );
}
