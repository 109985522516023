import PageLayout from '@/components/PageLayout';
import { Card, CardContent, Grid2 as Grid, Skeleton } from '@mui/material';

export default function PageLayoutLoading() {
    // You can add any UI inside Loading, including a Skeleton.
    return (
        <PageLayout>
            <Grid container spacing={3} maxWidth={(theme) => theme.spacing(120)}>
                <Grid size={{ xs: 12 }}>
                    <Card>
                        <CardContent>
                            <Skeleton width={'25%'} />
                            <Skeleton width={'100%'} height="50px" />
                            <Skeleton width={'100%'} height="50px" />
                            <Skeleton width={'70px'} height="50px" />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12 }}>
                    <Card>
                        <CardContent>
                            <Skeleton width={'25%'} />
                            <Skeleton width={'100%'} height="50px" />
                            <Skeleton width={'100%'} height="50px" />
                            <Skeleton width={'70px'} height="50px" />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </PageLayout>
    );
}
