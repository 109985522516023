import { CloseRounded, ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import RelativeBadge from '../RelativeBadge';
import { NetworkStatus, useLazyQuery } from '@apollo/client';
import { SingleNotification } from './SingleNotification';
import { Loading } from './Loading';
import { GroupNotificationsDocument } from '@/graphql/generated/group-notifications.generated';
import { forwardRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ArrayElement } from '@/utils/types/array-element';
import { NotificationsQuery } from '@/graphql/generated/notifications.generated';
import { NotificationTab, NotificationVariant } from '@/graphql/generated/types.generated';
import { MoreOptionsButton } from './MoreOptionsButton';
import { CustomContentProps, SnackbarContent, SnackbarKey, useSnackbar } from 'notistack';
import { SnackbarContent as SnackbarContentMui } from '@mui/material';
import { translationKeyNotificationArea } from '@/utils/notifications/translation-key-notification-area';
import { blue, red } from '@mui/material/colors';

export type StackedNotificationProps = ArrayElement<
    NotificationsQuery['notifications']['items']
>['stacked'] & {
    tab: NotificationTab;
    toaster?: boolean;
    toasterId?: SnackbarKey;
};
export const StackedNotification = (props: StackedNotificationProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [groupNotifications, { loading, error, data, fetchMore, networkStatus }] = useLazyQuery(
        GroupNotificationsDocument,
        { notifyOnNetworkStatusChange: true },
    );

    const { closeSnackbar } = useSnackbar();

    const handleCollapse = () => {
        setOpen(!open);
        if (!open && props) {
            groupNotifications({ variables: { group: props.group } });
        }
    };

    const { count, area, group, isRead, last2, tab, toaster, variant, toasterId } = props;
    const errorWarning = [NotificationVariant.Error, NotificationVariant.Warning].includes(variant);
    return (
        <Stack>
            <Stack
                sx={{
                    ...(!toaster && {
                        ':hover': {
                            bgcolor: 'action.hover',
                            ['.MuiIconButton-root']: {
                                visibility: 'visible',
                            },
                        },
                    }),
                }}
            >
                <Stack direction="row" gap={2} alignItems="center" flex={1}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        bgcolor={errorWarning ? red[50] : 'background.paper-elevation-8'}
                        p={(theme) => theme.spacing(0.5, 1.5)}
                        {...(toaster && {
                            borderRadius: (theme) => theme.spacing(1, 1, 0, 0),
                        })}
                        flex={1}
                    >
                        <Stack direction="row" gap={0.5}>
                            <Typography variant="caption" color={errorWarning ? red[900] : blue[900]}>
                                <Trans
                                    i18nKey="str.notifications.stacked.overview"
                                    values={{ feature: t(translationKeyNotificationArea(area)), count }}
                                />
                            </Typography>
                        </Stack>
                        <IconButton size="small" color="info" onClick={handleCollapse}>
                            {!open ? (
                                <ExpandMoreRounded fontSize="small" />
                            ) : (
                                <ExpandLessRounded fontSize="small" />
                            )}
                        </IconButton>
                        {!toaster && (
                            <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                                m={(theme) => theme.spacing(0, 0, 0, 'auto')}
                            >
                                <MoreOptionsButton group={group} isRead={isRead} tab={tab} />
                                {!isRead && <RelativeBadge variant="dot" color="info" />}
                            </Stack>
                        )}
                        {toaster && (
                            <IconButton
                                onClick={() => closeSnackbar(toasterId)}
                                size="small"
                                sx={{
                                    m: (theme) => theme.spacing(0, 0, 0, 'auto'),
                                }}
                            >
                                <CloseRounded fontSize="small" />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
                <Divider />
                {!open && (
                    <Stack p={(theme) => theme.spacing(1, 2)} gap={1} flex={1}>
                        <Typography variant="subtitle2" fontSize={12} color="text.primary" noWrap>
                            <Trans
                                i18nKey={last2[0].message}
                                values={last2[0].metadata}
                                components={{
                                    b: <strong />,
                                }}
                                count={last2[0].count}
                            />
                        </Typography>
                        <Divider />
                        <Stack gap={1} direction="row">
                            <Typography variant="subtitle2" fontSize={12} color="text.primary" noWrap>
                                <Trans
                                    i18nKey={last2[1].message}
                                    values={last2[1].metadata}
                                    components={{
                                        b: <strong />,
                                    }}
                                    count={last2[1].count}
                                />
                            </Typography>

                            <Typography
                                variant="subtitle2"
                                color="primary.main"
                                fontSize={12}
                                margin={(theme) => theme.spacing('auto', 0, 0, 'auto')}
                            >
                                {count - 2 > 0 ? `+${count - 2}` : ''}
                            </Typography>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {open && (
                <>
                    {((loading &&
                        ![NetworkStatus.fetchMore, NetworkStatus.refetch].includes(networkStatus)) ||
                        error) && <Loading rows={count} />}
                    {data?.groupNotifications.items?.map((item, i) => (
                        <div key={i}>
                            <SingleNotification {...item} tab={tab} insideStack />
                            {i < (data?.groupNotifications.items?.length || 0) - 1 && <Divider />}
                        </div>
                    ))}
                    {!loading && data?.groupNotifications.hasMore && (
                        <Button
                            size="small"
                            onClick={() =>
                                fetchMore({ variables: { skip: data?.groupNotifications.items?.length } })
                            }
                        >
                            {t('str.load-more')}
                        </Button>
                    )}
                    {loading && networkStatus === NetworkStatus.fetchMore && <Loading />}
                </>
            )}
        </Stack>
    );
};

type StackedNotificationToasterProps = { stacked: StackedNotificationProps } & CustomContentProps;
export const StackedNotificationToaster = forwardRef<HTMLDivElement, StackedNotificationToasterProps>(
    ({ ...props }, ref) => (
        <SnackbarContent ref={ref}>
            <SnackbarContentMui
                ref={ref}
                message={<StackedNotification {...props.stacked} toaster toasterId={props.id} />}
                sx={{
                    '.MuiSnackbarContent-message': {
                        width: '100%',
                    },
                }}
            />
        </SnackbarContent>
    ),
);
StackedNotificationToaster.displayName = 'StackedNotificationToaster';
