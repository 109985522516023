import './instrument.ts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { ErrorBoundary as ErrorBoundarySentry } from '@sentry/react';
import ErrorBoundary from './pages/ErrorBoundary.tsx';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';

import '@/libs/i18n/client.ts';
import ThemeProvider from './providers/theme-provider.tsx';

const cache = createCache({
    key: 'css',
    prepend: true,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <CacheProvider value={cache}>
            <ThemeProvider>
                <CssBaseline />
                <ErrorBoundarySentry fallback={<ErrorBoundary />}>
                    <App />
                </ErrorBoundarySentry>
            </ThemeProvider>
        </CacheProvider>
    </React.StrictMode>,
);
