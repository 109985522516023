import { NotificationsDocument, NotificationsQuery } from '@/graphql/generated/notifications.generated';
import { NotificationTab } from '@/graphql/generated/types.generated';
import { loadMoreScroll } from '@/utils/load-more-scroll';
import { NetworkStatus, QueryResult, useMutation, useQuery } from '@apollo/client';
import {
    AppBar,
    Divider,
    IconButton,
    Paper,
    Popover,
    Slide,
    SlideProps,
    Stack,
    styled,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { UIEvent, useState } from 'react';
import { drawerWidth, miniDrawerWidth } from '../nav/MainNav';
import { useSnackbar } from 'notistack';
import { CountUnreadNotificationsDocument } from '@/graphql/generated/count-unread-notifications.generated';
import { ClearAllNotificationsDocument } from '@/graphql/generated/toggle-clear-notification.generated';
import { ReadAllNotificationsDocument } from '@/graphql/generated/toggle-read-notification.generated';
import { ClearAllRounded, CloseRounded, MarkEmailReadRounded } from '@mui/icons-material';
import RelativeBadge from '../RelativeBadge';
import { Loading } from './Loading';
import { AllCaughtUp } from './CaughtUp';
import { SingleNotification } from './SingleNotification';
import { StackedNotification } from './StackedNotification';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@/providers/navigation-provider';

type HeaderProps = { switchTab: (tab: NotificationTab) => void; tab: NotificationTab; onClose: () => void };
const Header = ({ tab, switchTab, onClose }: HeaderProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useQuery(CountUnreadNotificationsDocument);
    const [clearAll, { loading: clearingAll }] = useMutation(ClearAllNotificationsDocument, {
        variables: { tab },
        refetchQueries: [CountUnreadNotificationsDocument, NotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.all.clear')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const [readAll, { loading: readingAll }] = useMutation(ReadAllNotificationsDocument, {
        variables: { tab },
        refetchQueries: [CountUnreadNotificationsDocument, NotificationsDocument],
        onCompleted: () => enqueueSnackbar(t('snackbar.notification.all.read')),
        onError: (e) => enqueueSnackbar(e.message),
    });
    const { t } = useTranslation();

    const loading = clearingAll || readingAll;
    return (
        <AppBar position="sticky" color="default" elevation={0}>
            <Stack padding={2} direction="row" justifyContent="space-between">
                <Typography variant="h5">{t('notifications.modal.title')}</Typography>
                <Stack direction="row">
                    <Tooltip title={t('notifications.modal.action.icon-button.clearall.clear-all-read')}>
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => clearAll()}
                                disabled={
                                    loading ||
                                    tab === NotificationTab.Cleared ||
                                    data?.countUnreadNotifications.find((item) => item.tab === tab)?.value ===
                                        0
                                }
                            >
                                <ClearAllRounded fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('notifications.modal.action.icon-button.checkall.mark-all-read')}>
                        <span>
                            <IconButton
                                size="small"
                                onClick={() => readAll()}
                                disabled={
                                    loading ||
                                    data?.countUnreadNotifications.find((item) => item.tab === tab)?.value ===
                                        0
                                }
                            >
                                <MarkEmailReadRounded fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {/* <Tooltip title={t('notifications.modal.action.icon-button.sound.mute')}>
                        <IconButton size="small">
                            <VolumeOffRounded fontSize="small" />
                        </IconButton>
                    </Tooltip> */}
                    <IconButton
                        size="small"
                        onClick={onClose}
                        sx={(theme) => ({
                            [theme.breakpoints.up('sm')]: {
                                display: 'none',
                            },
                        })}
                    >
                        <CloseRounded fontSize="small" />
                    </IconButton>
                </Stack>
            </Stack>

            <Tabs
                value={tab}
                sx={{
                    height: 38,
                    minHeight: 38,
                }}
                variant="fullWidth"
            >
                {data?.countUnreadNotifications.map((v) => {
                    let label = '';
                    switch (v.tab) {
                        case NotificationTab.All:
                            label = t('notifications.modal.tab.title.important');
                            break;
                        case NotificationTab.Cleared:
                            label = t('notifications.modal.tab.title.cleared');
                            break;
                        case NotificationTab.Warning:
                            label = t('notifications.modal.tab.title.warnings');
                            break;
                    }
                    return (
                        <Tab
                            key={v.tab}
                            value={v.tab}
                            label={label}
                            sx={{ textTransform: 'capitalize', minHeight: 38, fontSize: 12 }}
                            iconPosition="end"
                            icon={
                                <RelativeBadge
                                    badgeContent={v.value}
                                    color={v.tab === NotificationTab.Warning ? 'error' : 'primary'}
                                />
                            }
                            onClick={() => switchTab(v.tab)}
                        />
                    );
                })}
            </Tabs>
            <Divider />
        </AppBar>
    );
};

const StyledPopover = styled(Popover)(({ theme }) => ({
    '.MuiPopover-paper': {
        width: 380,
        height: '100%',
        overflow: 'hidden',
        borderRadius: 16,
        border: '1px solid #C8C5CA',
    },
    [theme.breakpoints.down('sm')]: {
        '.MuiPopover-paper': {
            left: '0 !important',
            top: '0 !important',
            borderRadius: 0,
            width: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
        },
    },
}));

type NotificationsProps = { open: boolean; onClose: () => void; query: QueryResult<NotificationsQuery> };
export const Notifications = ({ open, onClose, query }: NotificationsProps) => {
    const [tab, setTab] = useState(NotificationTab.All);
    const { loading, error, data, refetch, fetchMore, networkStatus } = query;
    const { mainNavOpen } = useNavigation();

    const handleSwitchTab = (tab: NotificationTab) => {
        setTab(tab);
        refetch({ tab });
    };

    const handleScroll = (e: UIEvent<HTMLDivElement>) =>
        loadMoreScroll(e, 120, data?.notifications.hasMore, () =>
            fetchMore({ variables: { skip: data?.notifications.items?.length } }),
        );

    const { total, items } = data?.notifications || { items: [], hasMore: false, total: 0 };
    return (
        <StyledPopover
            open={open}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 0, left: (mainNavOpen ? drawerWidth : miniDrawerWidth) + 4.5 }}
            slotProps={{
                paper: {
                    elevation: 6,
                    sx: {
                        padding: 0,
                    },
                },
            }}
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'right' } as SlideProps}
        >
            <Header tab={tab} switchTab={handleSwitchTab} onClose={onClose} />
            {(loading && ![NetworkStatus.fetchMore, NetworkStatus.refetch].includes(networkStatus)) ||
            Boolean(error) ? (
                <Loading />
            ) : total === 0 ? (
                <AllCaughtUp />
            ) : (
                <Paper sx={{ height: 'calc(100% - 100px)', overflow: 'auto' }} onScroll={handleScroll}>
                    {items?.map((item, i) => (
                        <div key={i}>
                            {item.single && <SingleNotification {...item.single} tab={tab} />}
                            {item.stacked && <StackedNotification {...item.stacked} tab={tab} />}
                            <Divider />
                        </div>
                    ))}
                    {loading && networkStatus === NetworkStatus.fetchMore && <Loading />}
                </Paper>
            )}
        </StyledPopover>
    );
};
