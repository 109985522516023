import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { useSession } from './session-provider';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Context = createContext<{
    openChatSupport: () => void;
    openHelpResources: () => void;
} | null>(null);

export type productFruitsAppCenter = {
    attachToCustomElement: (element: HTMLElement) => void;
    open: () => void;
    show: () => void;
    close: () => void;
    isOpened: () => boolean;
};

declare global {
    interface Window {
        productFruits: {
            api: {
                inAppCenter: productFruitsAppCenter;
                surveys: { startSurvey: (id: string) => void };
            };
        };
    }
}

export type tawkTo = {
    minimize: () => void;
    maximize: () => void;
    isChatMinimized: () => boolean;
    setAttributes: (attr: object) => void;
};

export function HelpResourcesProvider({ children }: { children: React.ReactNode }) {
    const { me } = useSession();

    const wProductFruits = window.productFruits;
    const tawkMessengerRef = useRef<tawkTo>();

    const justOpenedProductFruits = useRef(false);

    const tawkToOnLoad = useCallback(() => {
        if (me.publicId && tawkMessengerRef.current) {
            tawkMessengerRef.current.setAttributes({
                userId: me.publicId.toString() || '0',
                name: me.name || '-',
                email: me.email || 'invalid@email.user',
                // TODO: Add current team/workspace id support
                //team
            });
        }
    }, [me, tawkMessengerRef]);

    // Handle help center open/close
    const clickEvent = useCallback(
        (event: MouseEvent) => {
            const lifeRingPopup = document.querySelector('.productfruits--container');
            const target = (event.target as Node) || null;

            if (target && tawkMessengerRef.current) {
                if (
                    lifeRingPopup &&
                    !lifeRingPopup.contains(target) &&
                    wProductFruits.api.inAppCenter.isOpened() &&
                    !justOpenedProductFruits.current
                ) {
                    wProductFruits.api.inAppCenter.close();
                }
                justOpenedProductFruits.current = false;
            }
        },
        [wProductFruits, tawkMessengerRef, justOpenedProductFruits],
    );

    const openChatSupport = useCallback(() => {
        if (tawkMessengerRef.current) {
            tawkMessengerRef.current.maximize();
        }
    }, [tawkMessengerRef]);

    const openHelpResources = useCallback(() => {
        if (wProductFruits) {
            wProductFruits.api.inAppCenter.show();
            wProductFruits.api.inAppCenter.open();
            justOpenedProductFruits.current = true;
        }
    }, [wProductFruits, justOpenedProductFruits]);

    const productFruitsOpenChat = useCallback(() => {
        openChatSupport();
    }, [openChatSupport]);

    useEffect(() => {
        window.addEventListener('productfruits_button_ext_widget_init', productFruitsOpenChat);
        document.addEventListener('click', clickEvent);
        return () => {
            window.removeEventListener('productfruits_button_ext_widget_init', productFruitsOpenChat);
            document.removeEventListener('click', clickEvent);
        };
    }, [clickEvent, productFruitsOpenChat]);

    return (
        <>
            <TawkMessengerReact
                propertyId={import.meta.env.VITE_TAWKTO_PROPERTY}
                widgetId={import.meta.env.VITE_TAWKTO_WIDGET}
                ref={tawkMessengerRef}
                onLoad={tawkToOnLoad}
            />
            <Context.Provider value={{ openChatSupport, openHelpResources }}>{children}</Context.Provider>
        </>
    );
}

export function useHelpResources() {
    const currentContext = useContext(Context);
    if (!currentContext) {
        throw new Error();
    }
    return currentContext;
}
